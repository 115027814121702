body {
  font-family: "Poppins", sans-serif;
  background: #f3f9ff;
}

a {
  color: #fff;
  font-weight: 600;
  text-decoration: none;
}

a:hover {
  color: rgba(255, 255, 255, 0.75);
}

/* NAVBAR */
#navbar {
  border-radius: 25px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: linear-gradient(
    184deg,
    rgba(59, 217, 186, 0.55) 3.53%,
    rgba(48, 172, 148, 0.55) 104.46%
  );
  box-shadow: 0px 4px 34px 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(22px);
  padding: 0 35px;
}

#navToggle {
  border: 1px solid #fff;
}

#logo {
  width: 150px;
  height: 64.001px;
  flex-shrink: 0;
}

.navLink {
  color: #fff;
  font-weight: 600;
  margin-right: 30px;
}

#navButton {
  background-color: #fff;
  border: none;
  border-radius: 10px;
  color: #3bd9ba;
  padding: 7px 20px;

  transition: all 0.3s ease;
}

#navButton:hover {
  filter: drop-shadow(0px 0px 15px #19b294);
  background-color: #35c2a7;
  color: #fff;
}

#navButton:active {
  background-color: #19b294;
  color: #fff;
}

/* HERO SECTION */
#heroSection h1 {
  font-size: 52px;
  font-weight: 200;
  line-height: 52px;
}

#heroSection h1 span {
  color: #35c2a7;
  font-weight: 400;
}

#heroSection h2 {
  font-size: 40px;
  font-weight: 200;
  line-height: 48px;
  margin-top: 30px;
}

#heroSection h2 span {
  color: #35c2a7;
}

#heroSection p {
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  margin-top: 30px;
}

#heroSection p span {
  color: #35c2a7;
}

#heroButton {
  background-color: #3bd9ba;
  border: none;
  border-radius: 10px;
  color: #fff;
  padding: 15px 25px;

  margin-top: 20px;
  transition: all 0.3s ease;
}

#heroButton:hover {
  filter: drop-shadow(0px 0px 15px #19b294);
}

#heroButton:active {
  background-color: #35c2a7;
}

/* SOLUTIONS SECTION */
#solutionSection {
  background-color: #fff;
  border-radius: 50px 50px 0 0;
  box-shadow: 0px -6px 17px 0px #35c2a7;
  width: 100%;
  padding-bottom: 80px;
}

#solutionSection h2 {
  font-size: 40px;
  font-weight: 400;
}

#solutionSection p {
  margin-top: 30px;
}

#solutionSection p span {
  color: #35c2a7;
}

#solutionsSubSection {
  margin-top: 80px;
}

#solutionsSubSection h2 {
  font-weight: 600;
  color: #35c2a7;
  margin-bottom: 0;
}

#solutionsSubSection p {
  font-size: 24px;
  margin-top: 5px;
}

/* FORM SECTION */
#formSection {
  background-color: #e7f5f3;
  padding: 50px 0 100px;
}

#formSection h3 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 16px;
}

#formSection p {
  font-size: 24px;
  font-weight: 400;
  line-height: 1.3;
}

.input-group-text {
  padding: 11px 0px;
  background-color: #3bd9ba;
  color: #fff;
  font-weight: 400;
  min-width: 100px;
  display: flex;
  justify-content: center;
}

.form-control::placeholder {
  color: #bdbdbd;
}

#formButton {
  background-color: #3bd9ba;
  border: none;
  padding: 12px 20px;
  font-size: 20px;
  font-weight: 600;
  width: 140px;
  margin-left: auto;
}

#formButton:hover {
  box-shadow: 0px 4px 16px 0px #41efcd;
}

#formButton:active {
  background-color: #35c2a7;
}

#footerSection {
  background: linear-gradient(
    184deg,
    rgba(59, 217, 186, 0.55) 3.53%,
    rgba(48, 172, 148, 0.55) 104.46%
  );
}

#footerSection p {
  font-size: 14px;
  margin-bottom: 0;
}

#privacyPolicy {
  font-size: 36px;
  margin-bottom: 0;
}

#privacyPolicy h2 {
  font-size: 36px;
  margin-bottom: 0;
}

#privacyPolicy h3 {
  font-size: 24px;
  font-style: italic;
  color: #35c2a7;
  margin-bottom: 16px;
}

#privacyPolicy h4 {
  font-size: 16px;
  font-weight: 700;
  color: #35c2a7;
  margin-bottom: 0;
}

#privacyPolicy p {
  font-size: 16px;
}

#privacyPolicy li {
  font-size: 16px;
}

@media screen and (max-width: 575px) {
  #heroText {
    padding-right: 20px;
  }

  #solutionsText {
    padding-right: 20px;
  }

  #solutionsSubSection {
    margin-top: 40px;
  }

  #formSectionContainer {
    padding: 0 20px;
  }

  #footerSection {
    padding: 0 20px;
  }

  #footerSection {
    font-size: 12px;
  }
}

@media screen and (min-width: 1200px) {
  #heroText {
    padding-right: 60px;
  }

  #solutionsText {
    padding-right: 60px;
  }

  #solutionsSubSection {
    margin-top: 40px;
  }

  #formSectionContainer {
    padding: 0 150px;
  }
}
